import { ReactNode } from "react";
import { ToastMessage } from "../../../components/ui/ToastMessage";
import Stepper from "../Stepper/Stepper";
import SupportButton from "../../SupportButton/SupportButton";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { getCompanyData } from "../../../utils";

import styles from "./Layout.module.scss";

interface Props {
  children: ReactNode;
  hideStepper?: boolean;
  hideLogo?: boolean;
  hideSupportButton?: boolean;
  className?: string;
}

const Layout = ({
  children,
  className,
  hideStepper,
  hideLogo,
  hideSupportButton,
}: Props) => {
  const { container, header, body, button, companyLogo } = styles;

  const dimensions = useWindowWidth();
  const isMobile = dimensions.width < 768;

  return (
    <div className={container}>
      <div className={header}>
        {!hideStepper && !isMobile && <Stepper />}
        {!hideLogo && (
          <img
            className={companyLogo}
            src={getCompanyData().logo}
            alt={`${getCompanyData().companyName} logo`}
          />
        )}
      </div>
      <ToastMessage messageId="layoutPage" position="top-right" />
      <div className={`${body} ${className}`}>{children}</div>
      {!hideSupportButton && <SupportButton className={button} />}
    </div>
  );
};

export default Layout;

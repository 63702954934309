import { DragEvent, useCallback, useEffect, useRef, useState } from "react";
import { saveAs } from "file-saver";
import { FileData, InputFileProps } from "./types";

import UploadIconByma from "../../../assets/images/icons/ui/ui-icon-file_upload-byma.svg";

import ErrorIconByma from "../../../assets/images/icons/alert/ui-alert-icon-error-exclamation-filled-byma.svg";

import SuccessIconByma from "../../../assets/images/icons/ui/ui-icon-success-bg-dark-byma.svg";

import TrashIconByma from "../../../assets/images/icons/ui/ui-icon-trash-byma.svg";

import DownloadIconByma from "../../../assets/images/icons/ui/ui-icon-download-byma.svg";

import plusIconByma from "../../../assets/images/icons/ui/ui-icon-plus-circle-byma.svg";

import LoadingDots from "../LoadingDots/LoadingDots";

import styles from "./InputFile.module.scss";

const InputFile = ({
  name,
  text,
  infoText,
  onFileUpload,
  onFileRemove,
  onFileDownload,
  maxSize, // in MB
  validTypes,
  initialValue,
  className,
  selectedFile,
  fileData,
  error,
  isLoading,
  infoTextPosition = "bottom",
  infoTextClassName,
  hideDownloadIcon,
  label,
  isMobile,
  successMessage,
  formatErrorMessage = "Formato de archivo inválido.",
  sizeErrorMessage = "El archivo adjunto excede el peso máximo permitido de",
  replaceImageText = "Reemplazar imagen",
  attachImageText = "Adjuntar imagen",
}: InputFileProps) => {
  const {
    inputFile,
    fileContent,
    errorMessageStyle,
    fileHeader,
    fileOptions,
    inputSuccess,
    container,
    label: labelStyle,
    infoTextStyle,
    topPosition,
    bottomPosition,
    // onDrag,
    mobile,
    successMessageStyle,
    hiddenInputFile,
  } = styles;

  // const [dragging, setDragging] = useState(false);
  const [file, setFile] = useState<File | FileData | null>(fileData ?? null);
  const [fileError, setFileError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const handleDrag = (
    e: DragEvent<HTMLDivElement> | DragEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    // if (e.type === "dragenter" || e.type === "dragover") {
    //   setDragging(true);
    // } else if (e.type === "dragleave") {
    //   setDragging(false);
    // }
  };

  const handleFileChange = (inputFile: File) => {
    const allowedTypes = validTypes ? new Set(validTypes) : new Set([]);
    const maxFileSize = maxSize ? maxSize * 1024 * 1024 : 0;

    if (
      (!validTypes || allowedTypes.has(inputFile.type)) &&
      (!maxSize || inputFile.size <= maxFileSize)
    ) {
      setFileError(false);
      setFile(inputFile);
      onFileUpload && !isLoading && onFileUpload(inputFile);
    } else {
      setFile(inputFile);
      setFileError(true);
      onFileRemove && onFileRemove();
      allowedTypes.size !== 0 && !allowedTypes.has(inputFile.type)
        ? setErrorMessage(formatErrorMessage)
        : setErrorMessage(`${sizeErrorMessage} ${maxSize}MB.`);
    }
  };

  const handleDropOrInputChange = (
    e: React.DragEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    // setDragging(false);

    const droppedFile =
      "dataTransfer" in e
        ? e.dataTransfer.files[0]
        : e.target.files && e.target.files[0];

    if (droppedFile) {
      handleFileChange(droppedFile);
    }
  };

  const handleFileDownload = () => {
    if (fileData) {
      onFileDownload && onFileDownload(fileData);
    } else {
      file instanceof File &&
        file?.arrayBuffer().then((arrayBuffer) => {
          const blob = new Blob([new Uint8Array(arrayBuffer)], {
            type: file.type,
          });
          saveAs(blob, `${file.name}`);
        });
    }
  };

  const handleFileRemove = useCallback(() => {
    setFile(null);
    setFileError(false);
    hiddenInputRef.current && (hiddenInputRef.current.value = "");
    onFileRemove && onFileRemove();
  }, []);

  useEffect(() => {
    if (selectedFile) {
      handleFileChange(selectedFile);
    }
  }, [selectedFile]);

  useEffect(() => {
    if (initialValue) {
      handleFileChange(initialValue);
    } else {
      setFile(null);
    }
  }, [initialValue]);

  useEffect(() => {
    if (fileData) setFile(fileData);
  }, [fileData]);

  useEffect(() => {
    if (error) {
      setFileError(true);
      onFileRemove && onFileRemove();
    }
  }, [error]);

  return (
    <div
      className={`${container} ${className ?? ""}`}
      id={`inputFile-container-${name}`}
    >
      <input
        ref={hiddenInputRef}
        className={hiddenInputFile}
        type="file"
        accept={validTypes?.join(",")}
        onChange={handleDropOrInputChange}
        id={`input-file-upload-${name}`}
        multiple={false}
      />
      {isMobile ? (
        <label
          htmlFor={`input-file-upload-${name}`}
          style={{ width: "100%", height: "100%" }}
          className={`${
            infoTextPosition === "top" ? topPosition : bottomPosition
          }`}
        >
          {label && <div className={labelStyle}>{label}</div>}
          <div className={mobile}>
            <span>{file ? replaceImageText : attachImageText}</span>
            {!file && <img src={plusIconByma} alt="adjuntar" />}
          </div>
          {infoText && (
            <div className={`${infoTextStyle} ${infoTextClassName}`}>
              {infoText}
            </div>
          )}
        </label>
      ) : (
        <>
          {!file || fileError ? (
            <>
              <label
                htmlFor={`input-file-upload-${name}`}
                style={{ width: "100%", height: "100%" }}
                className={`${
                  infoTextPosition === "top" ? topPosition : bottomPosition
                }`}
              >
                {label && <div className={labelStyle}>{label}</div>}
                <div
                  onDragEnter={handleDrag}
                  onDragOver={handleDrag}
                  onDragLeave={handleDrag}
                  onDrop={handleDropOrInputChange}
                  className={inputFile}
                >
                  <div className={fileHeader}>
                    <img src={UploadIconByma} />
                    <label
                      id={`label-file-upload-${name}`}
                      htmlFor={`input-file-upload-${name}`}
                    >
                      {text}
                    </label>
                  </div>
                </div>
                {infoText && (
                  <div className={`${infoTextStyle} ${infoTextClassName}`}>
                    {infoText}
                  </div>
                )}
              </label>
            </>
          ) : (
            <div
              className={`${
                infoTextPosition === "top" ? topPosition : bottomPosition
              }`}
            >
              {label && <div className={labelStyle}>{label}</div>}
              <div className={`${inputFile} ${!isLoading ? inputSuccess : ""}`}>
                <div className={fileContent}>
                  <div className={`${fileHeader} ${file && "w-4/5"}`}>
                    {isLoading ? (
                      <>
                        <img src={UploadIconByma} />
                        <label
                          id={`label-file-upload-${name}`}
                          htmlFor={`input-file-upload-${name}`}
                        >
                          Cargando {file?.name}
                        </label>
                      </>
                    ) : (
                      <>
                        <img src={SuccessIconByma} />
                        <p>{file?.name}</p>
                      </>
                    )}
                  </div>
                  <div className={fileOptions}>
                    {isLoading ? (
                      <LoadingDots color="#acb3bf" />
                    ) : (
                      <>
                        {!hideDownloadIcon && (
                          <img
                            src={DownloadIconByma}
                            onClick={handleFileDownload}
                          />
                        )}
                        <img src={TrashIconByma} onClick={handleFileRemove} />
                      </>
                    )}
                  </div>
                </div>
              </div>
              {infoText && (
                <div className={`${infoTextStyle} ${infoTextClassName}`}>
                  {infoText}
                </div>
              )}
            </div>
          )}
        </>
      )}
      {isMobile && file && !fileError && (
        <div className={successMessageStyle}>
          <img src={SuccessIconByma} />
          <p>{successMessage ?? "Archivo cargado con éxito"}</p>
        </div>
      )}
      {fileError && (
        <div className={errorMessageStyle}>
          <img src={ErrorIconByma} />
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  );
};

export default InputFile;

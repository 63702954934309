import styles from "./SuccessPage.module.scss";
import successIconByma from "../../assets/images/icons/ui/ui-icon-success-byma.svg";
import Container from "../ui/Container/Container";
import Layout from "../ui/Layout/Layout";
import { getCompanyData } from "../../utils";

const SuccessPage = () => {
  const { successContainer, body, logosContainer, companyLogo } = styles;

  return (
    <Layout hideLogo hideSupportButton>
      <Container className={successContainer}>
        <div className={logosContainer}>
          <img
            className={companyLogo}
            src={getCompanyData().logo}
            alt={`${getCompanyData().companyName} logo`}
            width={160}
          />
        </div>
        <div className={body}>
          <img src={successIconByma} alt="success" />
          <h1>¡Terminaste el registro!</h1>
          <p>
            Procesaremos tu solicitud y recibirás un mail a la brevedad con las
            instrucciones para acceder a tu cuenta.
          </p>
        </div>
      </Container>
    </Layout>
  );
};

export default SuccessPage;

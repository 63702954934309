import styles from "./Message.module.scss";

import ErrorIconByma from "../../../assets/images/icons/alert/ui-alert-icon-error-exclamation-filled-byma.svg";
import SuccessIconByma from "../../../assets/images/icons/ui/ui-icon-success-bg-dark-byma.svg";
import InfoIconByma from "../../../assets/images/icons/alert/ui-alert-icon-info-byma.svg";

import { MessageProps } from "./types";

const Message = ({ message, className, variant }: MessageProps) => {
  const { messageContainer, errorStyle, successStyle, infoStyle } = styles;

  const variantStyles = {
    error: errorStyle,
    success: successStyle,
    info: infoStyle,
  };
  const variantIcon = {
    error: ErrorIconByma,
    success: SuccessIconByma,
    info: InfoIconByma,
  };

  return (
    <div
      className={`${messageContainer} ${variantStyles[variant]} ${className}`}
    >
      <img src={variantIcon[variant]} alt={`${variantIcon[variant]} icon`} />
      <span>{message}</span>
    </div>
  );
};

export default Message;

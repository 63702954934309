import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeModal } from "../../reducers/modalReducer";
import { Modal } from "../../components/ui/Modal";
import { Button } from "../../components/ui/Button";

import OldDNI from "../../assets/images/oldDNI.svg?react";
import NewDNI from "../../assets/images/newDNI.svg?react";

import styles from "./TramitNumberModal.module.scss";

const TramitNumberModal = () => {
  const {
    headerTitle,
    wrapper,
    dniPhoto,
    modalBaseMobile,
    modalBackDropMobile,
  } = styles;

  const dispatch = useAppDispatch();
  const { modalName } = useAppSelector((state) => state.modal);

  const isMobile = window.innerWidth < 768;

  const handleClose = () => dispatch(closeModal());

  return (
    <Modal
      isOpen={modalName === "modal-tramit-number"}
      onClose={handleClose}
      hideCloseButton={isMobile}
      width={isMobile ? "100%" : "520px"}
      header={
        <h1 className={headerTitle}>¿Dónde encuentro mi número de trámite?</h1>
      }
      footer={
        <Button text="Cerrar" onClick={handleClose} fullWidth={isMobile} />
      }
      {...(isMobile
        ? {
            classNames: {
              base: modalBaseMobile,
              backdrop: modalBackDropMobile,
            },
          }
        : undefined)}
    >
      <div className={wrapper}>
        <div>
          <p>Si tu DNI es posterior al 2013 encontralo en el frente.</p>
          <p> Si es previo, encontralo en el dorso.</p>
        </div>
        <NewDNI className={dniPhoto} />
        <OldDNI className={dniPhoto} />
      </div>
    </Modal>
  );
};

export default TramitNumberModal;

import React from "react";
import { BasicStep, Step } from "../../../types";
import { ToastMessage } from "../../../components/ui/ToastMessage";
import DotStep from "../DotStep/DotStep";
import useWindowWidth from "../../../hooks/useWindowWidth";

import styles from "./FormContainer.module.scss";

interface FormContainerProps {
  currentStep?: Step;
  children: React.ReactNode;
  icon?: string;
  className?: string;
  currentSubStep: number;
}

const FormContainer = ({
  currentStep,
  currentSubStep,
  children,
  icon,
  className,
}: FormContainerProps) => {
  const {
    formContainer,
    infoSection,
    header,
    headerContainer,
    childrenStyle,
    modalAlert,
  } = styles;

  const hasSubSteps = !!currentStep?.subSteps;
  const hideSubStepper = currentStep?.hideStepper;
  const subtitle =
    currentStep?.subtitle ||
    (currentStep?.subSteps?.find((s) => s.step === currentSubStep) as BasicStep)
      ?.subtitle;

  const title =
    (currentStep?.subSteps?.find((s) => s.step === currentSubStep) as BasicStep)
      ?.titleOfStep || currentStep?.name;

  const dimensions = useWindowWidth();
  const isMobile = dimensions.width < 768;

  return (
    <div className={formContainer}>
      <ToastMessage
        messageId="formAlert"
        position="top-center"
        className={modalAlert}
      />
      <div className={headerContainer}>
        <div className={header}>
          <div className={infoSection}>
            {icon && !isMobile && <img src={icon} />}
            <h1>{title} </h1>
          </div>
          <h3>{subtitle}</h3>
        </div>
        <div>
          {!hideSubStepper && hasSubSteps && (
            <DotStep
              currentSubStep={currentSubStep}
              subSteps={currentStep?.subSteps}
            />
          )}
        </div>
      </div>

      <div className={`${childrenStyle} ${className}`}>{children}</div>
    </div>
  );
};

export default FormContainer;

import { yupSelect, emailRequired, YupNumber } from "../validations";
import { Template } from "../types";

import agroLogo from "../assets/allaria-fondos.png";

const options = [
  { value: "1", label: "110293810293" },
  { value: "2", label: "2918239128" },
  { value: "3", label: "3918888888" },
  { value: "4", label: "411928740857" },
];

const onboardingJuridical: Template = {
  formTitle: "Onboarding Allaria Fondos",
  logo: agroLogo,
  favicon: agroLogo,
  steps: [
    {
      step: 1,
      name: "Información básica",
      fields: [
        {
          initialValue: "",
          type: "email",
          name: "email",
          label: "Email",
          validation: emailRequired,
        },
        {
          initialValue: "",
          type: "text",
          name: "age",
          label: "Age",
          validation: YupNumber({
            min: 18,
            minMessage: "Debes ser mayor de 18 años",
            required: true,
            requiredMessage: "Debes ser mayor de 18 años",
          }),
        },
      ],
    },
    {
      step: 2,
      name: "Validación de identidad",
      fields: [
        {
          name: "quantity",
          type: "select",
          placeholder: "Seleccioná una cuenta mayorista o distribuidora",
          validation: yupSelect({
            type: "single",
            options,
            optionsMessage: "La opción seleccionada no es válida.",
            required: true,
            requiredMessage: "El campo es requerido.",
          }),
          initialValue: "",
          options,
          label: "Cuenta mayorista",
          selectType: "single",
        },
      ],
    },
  ],
  onboardingType: "JURIDICAL",
  preconfirmation: {
    steps: [],
    checkboxes: [],
  },
};

export default onboardingJuridical;

import TableWithDots from "../ui/TableWithDots/TableWithDots";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeModal } from "../../reducers/modalReducer";
import { Modal } from "../../components/ui/Modal";
import { Button } from "../../components/ui/Button";

import styles from "./RiskProfileModal.module.scss";

const RiskProfileModal = () => {
  const { modalBaseMobile, modalBackDropMobile, riskContainer, dot } = styles;
  const dispatch = useAppDispatch();
  const { modalName } = useAppSelector((state) => state.modal);

  const isMobile = window.innerWidth < 768;

  const handleClose = () => dispatch(closeModal());

  const title = `Compra y venta de ${isMobile ? ":" : ""}`;
  const risks = ["Riesgo bajo", "Riesgo medio", "Riesgo alto"];
  const transactions = [
    {
      title: "Títulos Públicos (nacionales, provinciales y municipales)",
      dots: [1, 2, 3],
    },
    { title: "Letras del BCRA", dots: [1, 2, 3] },
    { title: "Fideicomisos Financieros", dots: [1, 2, 3] },
    { title: "Cheques de pago diferido", dots: [1, 2, 3] },
    { title: "Fondos comunes de inversión de renta fija", dots: [1, 2, 3] },
    { title: "Obligaciones Negociables", dots: [1, 2, 3] },
    { title: "Caución Colocadora", dots: [1, 2, 3] },
    { title: "Préstamo Colocador", dots: [1, 2, 3] },
    { title: "Acciones ordinarias y preferidas", dots: [2, 3] },
    { title: "Fondos comunes de inversión de renta variable", dots: [2, 3] },
    { title: "Acciones negociadas en mercados del exterior", dots: [2, 3] },
    { title: "Préstamo tomador", dots: [2, 3] },
    {
      title:
        "Títulos públicos, privados, moneda, metales y productos agropecuarios",
      dots: [3],
    },
    { title: "Opciones de títulos privados y públicos", dots: [3] },
    { title: "Caución tomadora", dots: [3] },
    {
      title:
        "Activos con apalancamiento sobre garantía de dicho activo y/u otros",
      dots: [3],
    },
    { title: "Préstamo tomador con venta en corto", dots: [3] },
    { title: "Fondos comunes de inversión cerrados", dots: [3] },
  ];
  const riskInfoTexts: { [key: string]: string } = {
    "Riesgo medio": "Incluye las operaciones de riesgo bajo.",
    "Riesgo alto": "Incluye las operaciones de riesgo medio y bajo.",
  };

  return (
    <Modal
      isOpen={modalName === "modal-risk-profile"}
      width={isMobile ? "100%" : "80rem"}
      onClose={handleClose}
      header={!isMobile && "¿Qué transacciones implica cada riesgo?"}
      hideCloseButton={isMobile}
      {...(isMobile
        ? {
            classNames: {
              base: modalBaseMobile,
              backdrop: modalBackDropMobile,
            },
          }
        : undefined)}
      footer={
        isMobile && <Button text="Volver" onClick={handleClose} fullWidth />
      }
      centerFooter
    >
      {isMobile ? (
        <div>
          {risks.map((risk, index) => (
            <div key={`${risk}-${title}`} className={riskContainer}>
              <h3>{risk}</h3>
              <h4>{title}</h4>
              <ul>
                {transactions
                  .filter(
                    ({ dots }) =>
                      dots.includes(index + 1) &&
                      dots.every((dot) => dot >= index + 1)
                  )
                  .map(({ title }) => (
                    <div key={title}>
                      <span className={dot}>•</span>
                      <li>{title}</li>
                    </div>
                  ))}
              </ul>
              <span>{riskInfoTexts[risk]}</span>
            </div>
          ))}
        </div>
      ) : (
        <TableWithDots columns={[title, ...risks]} rows={transactions} />
      )}
    </Modal>
  );
};

export default RiskProfileModal;

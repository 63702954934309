import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { openModal } from "../../reducers/modalReducer";
import { Input, InputProps } from "../ui/Input";
import { InputSelect } from "../ui/InputSelect";
import { InputPhone } from "../ui/InputPhone";
import { InputFile } from "../ui/InputFile";
import { InputTextArea } from "../ui/InputTextArea";
import { InputAddress } from "../ui/InputAddress";
import { RadioGroup } from "../ui/RadioGroup";
import { ConditionalBool, Field, SegmentedInputOption } from "../../types";
import { FormikProps, FormikValues } from "formik";
import SegmentedOptionsInput from "../SegmentedOptionsInput/SegmentedOptionsInput";
import useWindowWidth from "../../hooks/useWindowWidth";
import { getNestedValue, isSchemaRequired } from "./utils";
import styles from "./TemplateInterpreter.module.scss";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface ColumnsWrapperProps {
  columns: number | undefined;
  children: React.ReactNode;
}

interface TemplateInputSelectorProps {
  currentStepFields?: Field[];
  field: Field;
  formik: FormikProps<FormikValues>;
}

const ColumnsWrapper = ({ columns, children }: ColumnsWrapperProps) => {
  const { columnsWrapper, twoColumn, threeColumn } = styles;

  const dimensions = useWindowWidth();
  const isMobile = dimensions.width < 768;

  const columnsClassNames = [];

  if (!isMobile && columns) {
    columnsClassNames.push(
      columns === 2 ? twoColumn : columns === 3 ? threeColumn : ""
    );
  }

  return (
    <div className={`${columnsWrapper} ${columnsClassNames.join(" ")}`}>
      {children}
    </div>
  );
};

interface ConditionalResetAndScrollTo {
  conditionalBoolCurrentField: ConditionalBool[] | undefined;
  boolFieldsWithSameConditionalField: ConditionalBool[] | undefined;
  values: FormikValues;
  setFieldValue: (field: string, value: unknown) => void;
  scrollToElementName: (name: string, isFileElement?: boolean) => void;
}

const conditionalResetAndScrollTo = ({
  conditionalBoolCurrentField,
  boolFieldsWithSameConditionalField,
  values,
  setFieldValue,
  scrollToElementName,
}: ConditionalResetAndScrollTo) => {
  // Scroll to conditional input to be visible
  if (conditionalBoolCurrentField && conditionalBoolCurrentField.length > 0) {
    setTimeout(() => {
      // If the current field triggers another conditional field
      for (const field of conditionalBoolCurrentField) {
        // If conditionalField have multiple boolFields, reset it only if all of them have no value
        if (
          Array.isArray(boolFieldsWithSameConditionalField) &&
          boolFieldsWithSameConditionalField?.length > 0
        ) {
          const allBoolFieldsHaveNoValue = [
            ...conditionalBoolCurrentField,
            ...boolFieldsWithSameConditionalField,
          ].every(
            (conditional) =>
              !getNestedValue(values, conditional.boolField as string)
          );

          if (allBoolFieldsHaveNoValue) {
            for (const field of boolFieldsWithSameConditionalField) {
              setFieldValue(field?.conditionalField, "");
            }
          }
        } else {
          // The conditional file is emptied
          setFieldValue(field?.conditionalField, "");
        }
      }

      if (!conditionalBoolCurrentField[0]?.dontScrollTo) {
        scrollToElementName(conditionalBoolCurrentField[0]?.conditionalField);
      }
    }, 200);
  }
};

const scrollToElementName = (name: string, isFileElement?: boolean) => {
  const element = isFileElement
    ? document.getElementById(`inputFile-container-${name}`)
    : document.querySelector(`[name="${name}"]`);
  element && element.scrollIntoView({ behavior: "smooth", block: "start" });
};

const TemplateInputSelector = ({
  currentStepFields,
  field,
  formik,
}: TemplateInputSelectorProps) => {
  const { labelInfo, optionalLabel, file: fileStyle, externalTooltip } = styles;

  const state = useAppSelector((state) => state);

  const {
    name,
    label,
    tooltip,
    type,
    options,
    selectType,
    confirmSelection,
    disabled,
    placeholder,
    maxSize,
    validTypes,
    infoText,
    infoTextPosition,
    onChange,
    onBlur,
    showExternalBox,
    conditional,
    conditionalFile,
    columns,
    autoSelect,
    country,
    validation,
    autoComplete,
    maxLength,
    dynamicOptions,
    disabledByUrlParams,
    showOptionalLabel,
    text,
  } = field;

  const {
    getFieldProps,
    values,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    errors,
    touched,
    handleBlur,
  } = formik;

  const error = getNestedValue(errors, name);

  const touch = getNestedValue(touched, name);

  const value = getNestedValue(values, name);

  const [disabledByUrl, setDisabledByUrl] = useState(false);

  const inputType = type;

  const segmentedOptions =
    type === "multiple-radio" && (options as SegmentedInputOption[]);

  const [searchParams] = useSearchParams();

  const getShouldConditionallyRender = () => {
    // If the field is shown conditionally by a file
    if (conditionalFile) {
      // If the file that is conditionally needed is present or the field has a value show the field
      return !!getNestedValue(values, conditionalFile.name) || !!value;
    }

    // If the field is shown conditionally by multiple fields
    if (conditional) {
      // If the file has a value dont hide the field
      if (inputType === "file" && !!value) {
        return true;
      }

      if (Array.isArray(conditional)) {
        // If any of the conditional fields have the correct value show the field
        return conditional.some((cond) => {
          const conditionalValue = getNestedValue(values, cond.name) as string;
          return Array.isArray(cond.value)
            ? cond.value.includes(conditionalValue)
            : cond.value === conditionalValue;
        });
        // If the conditional field is shown by a single field
      } else {
        // If the conditional field has the correct value show the field
        if (conditional.name) {
          const conditionalValue = getNestedValue(
            values,
            conditional.name
          ) as string;

          return Array.isArray(conditional.value)
            ? conditional.value.includes(conditionalValue)
            : conditional.value === conditionalValue;
        }
      }
    }

    return true;
  };

  const dispatch = useAppDispatch();

  const getLabel = () => {
    if (type !== "text" && type !== "dni" && tooltip) {
      return (
        <div className={"flex w-full justify-between items-center flex-wrap"}>
          <p>{label}</p>
          <p
            onClick={() => dispatch(openModal({ name: tooltip.modal }))}
            className={labelInfo}
          >
            {tooltip.label}
          </p>
        </div>
      );
    }

    if (type === "file") {
      return isSchemaRequired(validation) ? (
        label
      ) : (
        <div className={optionalLabel}>
          {label} {!conditionalFile && <span>- Opcional</span>}
        </div>
      );
    }

    return (
      <div className={optionalLabel}>
        {label}
        {showOptionalLabel && (
          <span>{isSchemaRequired(validation) ? "" : " - Opcional"}</span>
        )}
      </div>
    );
  };

  // To manage scroll to conditional fields
  const conditionalFieldsNames: ConditionalBool[] | undefined =
    currentStepFields
      ?.filter((field) => field.conditional || field.conditionalFile)
      .flatMap((field) => {
        if (field.conditionalFile) {
          return {
            boolField: field.conditionalFile?.name,
            conditionalField: field.name,
            dontScrollTo: Boolean(field.dontScrollTo),
          };
        } else if (Array.isArray(field.conditional)) {
          return field.conditional.map((conditional) => ({
            boolField: conditional.name,
            conditionalField: field.name,
            dontScrollTo: Boolean(field.dontScrollTo),
          }));
        }

        return {
          boolField: field.conditional?.name,
          conditionalField: field.name,
          dontScrollTo: Boolean(field.dontScrollTo),
        };
      });

  const conditionalBoolCurrentField = conditionalFieldsNames?.filter(
    (conditional) => conditional.boolField === name
  );

  const boolFieldsWithSameConditionalField = conditionalFieldsNames?.filter(
    (conditional) => {
      // If the current field is not a conditional field
      if (
        !conditionalBoolCurrentField ||
        conditionalBoolCurrentField.length === 0
      ) {
        return false;
      }

      // Filter the conditional fields that have the same conditional field
      if (conditionalBoolCurrentField[0].boolField === conditional.boolField) {
        return false;
      }

      // Return only the additional conditional fields that have the same conditional field
      return (
        conditional.conditionalField ===
        conditionalBoolCurrentField[0].conditionalField
      );
    }
  );
  useEffect(() => {
    if (disabledByUrlParams) {
      const foundParam = searchParams.get(disabledByUrlParams);
      if (foundParam) setDisabledByUrl(true);
    }
  }, [disabledByUrlParams]);

  //Allow to disable the field by a function, by url params or by a boolean
  const getDisabled = () => {
    if (disabled) {
      if (typeof disabled === "boolean") return disabled;
      else {
        return disabled(state);
      }
    } else return disabledByUrl;
  };

  const isInputDisabled: boolean = getDisabled();

  if (values && getShouldConditionallyRender()) {
    switch (type) {
      case "select":
        return (options && options.length > 0) || dynamicOptions ? (
          <ColumnsWrapper columns={columns}>
            <InputSelect
              key={name}
              label={getLabel()}
              type={selectType || "single"}
              items={
                options && options.length > 0
                  ? options
                  : dynamicOptions
                  ? dynamicOptions(formik)
                  : []
              }
              placeholder={placeholder}
              name={name}
              onChange={
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (value: any) => {
                  if (value.length === 0 && selectType === "multiple") {
                    setFieldValue(name, null);
                  } else {
                    setFieldValue(name, value);
                  }

                  conditionalResetAndScrollTo({
                    conditionalBoolCurrentField,
                    boolFieldsWithSameConditionalField,
                    values,
                    setFieldValue,
                    scrollToElementName,
                  });

                  if (field?.onChange) {
                    field.onChange(
                      value,
                      setFieldValue,
                      setFieldError,
                      setFieldTouched
                    );
                  }
                }
              }
              onBlur={() => {
                setFieldTouched(name, true);
              }}
              inputValue={value as string}
              isInvalid={!!error && !!touch}
              errorMessage={error as string}
              confirmSelection={confirmSelection}
              showExternalBox={!!showExternalBox}
              disabled={isInputDisabled}
              isDisabled={isInputDisabled}
            />
          </ColumnsWrapper>
        ) : null;
      case "radio":
        return options && options.length > 0 ? (
          <ColumnsWrapper columns={columns}>
            <RadioGroup
              key={name}
              label={label}
              options={options}
              disabled={isInputDisabled}
              {...getFieldProps(name)}
              onChange={(e) => {
                onChange && onChange(values, setFieldValue);
                formik.handleChange(e);
                conditionalResetAndScrollTo({
                  conditionalBoolCurrentField,
                  boolFieldsWithSameConditionalField,
                  values,
                  setFieldValue,
                  scrollToElementName,
                });
              }}
            />
          </ColumnsWrapper>
        ) : null;
      case "phone":
        return (
          <ColumnsWrapper columns={columns}>
            <InputPhone
              key={name}
              infoMessage={infoText}
              {...getFieldProps(name)}
              onChange={(value) => {
                setFieldValue(name, value);
              }}
              errorMessage={error as string}
              touched={!!touch}
              isValid={!!(touch && value && !error)}
            />
          </ColumnsWrapper>
        );
      case "file":
        return (
          <ColumnsWrapper columns={columns}>
            <InputFile
              name={name}
              key={name}
              maxSize={maxSize || 0}
              validTypes={validTypes || []}
              text={
                <p>
                  Arrastrá o <span>seleccioná un archivo</span> de tu
                  dispositivo
                </p>
              }
              className={fileStyle}
              label={getLabel()}
              infoText={infoText}
              infoTextPosition={infoTextPosition}
              onFileUpload={(file: File) => {
                setFieldValue(name, file);
                if (conditionalBoolCurrentField) {
                  setTimeout(() => {
                    scrollToElementName(
                      conditionalBoolCurrentField[0]?.conditionalField,
                      true
                    );
                  }, 200);
                }
              }}
              onFileRemove={() => setFieldValue(name, null)}
              initialValue={value as File}
            />
          </ColumnsWrapper>
        );
      case "token":
        return (
          <ColumnsWrapper columns={columns}>
            <p key={name}>Add InputToken</p>
          </ColumnsWrapper>
        );
      case "multiple-radio":
        return segmentedOptions && segmentedOptions.length > 0 ? (
          <ColumnsWrapper columns={columns}>
            <SegmentedOptionsInput
              key={name}
              label={label}
              options={segmentedOptions}
              isInvalid={!touched && !value}
              {...getFieldProps(name)}
            />
          </ColumnsWrapper>
        ) : null;
      case "textarea":
        return (
          <ColumnsWrapper columns={columns}>
            <InputTextArea
              key={name}
              label={label}
              type={inputType}
              isInvalid={!!error && !!touch}
              errorMessage={error as string}
              isDisabled={isInputDisabled}
              placeholder={placeholder}
              maxLength={maxLength}
              {...getFieldProps(name)}
            />
          </ColumnsWrapper>
        );
      case "address":
        return (
          <ColumnsWrapper columns={columns}>
            <InputAddress
              {...getFieldProps(name)}
              onBlur={(e) => {
                handleBlur(e);
              }}
              key={name}
              label={label}
              isInvalid={!!error && !!touch}
              errorMessage={error as string}
              disabled={isInputDisabled}
              placeholder={placeholder}
              onValueChange={async (address) => {
                if (!address) {
                  setFieldValue(name, "");
                  return;
                }
                setFieldValue(
                  name,
                  `${address.formattedAddress.street} ${address.formattedAddress.streetNumber}`
                );
                onChange && onChange(address, setFieldValue);
              }}
              country={country && (getNestedValue(values, country) as string)}
              autoSelect={
                autoSelect &&
                (!values.addresses.city || !values.addresses.municipality
                  ? true
                  : undefined) &&
                (getNestedValue(values, autoSelect) as string)
              }
              autoComplete={autoComplete}
              onChange={formik.handleChange}
            />
          </ColumnsWrapper>
        );
      case "dni":
      case "cuit":
      case "text":
      case "email":
        return (
          <ColumnsWrapper columns={columns} key={name}>
            {tooltip && tooltip.external && (
              <div
                className={externalTooltip}
                onClick={() => dispatch(openModal({ name: tooltip.modal }))}
              >
                {tooltip.label}
              </div>
            )}
            <Input
              label={getLabel()}
              type={inputType as InputProps["type"]}
              isInvalid={!!error && !!touch}
              errorMessage={error as string}
              isDisabled={isInputDisabled}
              placeholder={placeholder}
              initialValue={value as string}
              maxLength={maxLength}
              infoMessage={infoText}
              {...getFieldProps(name)}
              {...(onChange && {
                onChange: (element) =>
                  onChange(
                    values,
                    setFieldValue,
                    setFieldError,
                    setFieldTouched,
                    element
                  ),
              })}
              {...(onBlur && {
                onBlur: () =>
                  onBlur(values, setFieldValue, setFieldError, setFieldTouched),
              })}
              {...(tooltip &&
                !tooltip.external && {
                  info: "CUSTOM",
                  customInfo: (
                    <p
                      onClick={() =>
                        dispatch(openModal({ name: tooltip.modal }))
                      }
                      className={labelInfo}
                    >
                      {tooltip.label}
                    </p>
                  ),
                })}
            />
          </ColumnsWrapper>
        );
      case "label":
        return (
          <ColumnsWrapper columns={columns} key={name}>
            {text}
          </ColumnsWrapper>
        );
    }
  } else {
    return null;
  }
};

export default TemplateInputSelector;

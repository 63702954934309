import styles from "./InfoMessage.module.scss";
import InfoIconByma from "../../../assets/images/icons/alert/ui-alert-icon-info-byma.svg";
import { InfoMessageProps } from "./types";

const InfoMessage = ({ message, className }: InfoMessageProps) => {
  const { infoMessage } = styles;
  return (
    <div className={`${className ? className : ""}`}>
      <img src={InfoIconByma} />
      <span className={infoMessage}>{message}</span>
    </div>
  );
};

export default InfoMessage;

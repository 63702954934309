//Sentry
import "./sentry/instrument.ts";
import * as Sentry from "@sentry/react";
//Routes
import { createRoot } from "react-dom/client";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
//Components
import App from "./App.tsx";
import PreconfirmationPage from "./components/PreconfirmationPage/PreconfirmationPage.tsx";
import TemplateInterpreter from "./components/TemplateInterpreter";
import UploadDniCard from "./components/UploadDniCard/UploadDniCard.tsx";
import NotFoundPage from "./components/NotFoundPage/NotFoundPage.tsx";
//AWS Liveness
import "@aws-amplify/ui-react/styles.css";
import { ThemeProvider } from "@aws-amplify/ui-react";
import LivenessValidationCard from "./components/LivenessValidationCard/LivenessValidationCard.tsx";
//Redux
import { Provider } from "react-redux";
import { store } from "./app/store.ts";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
//Firebase
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import SuccessPage from "./components/SuccessPage/SuccessPage.tsx";
import { Amplify } from "aws-amplify";
import awsexports from "./aws-exports.ts";
//Styles
import "./styles/global.scss";
import { hotjar } from "react-hotjar";
//NextUI
import { NextUIProvider } from "@nextui-org/react";

const container = document.getElementById("root")!;
const root = createRoot(container);
export const persistor = persistStore(store);

if (import.meta.env.VITE_ENVIRONMENT !== "dev") {
  hotjar.initialize({
    id: Number(import.meta.env.VITE_HOTJAR_ID),
    sv: Number(import.meta.env.VITE_HOTJAR_SV),
  });
}

const firebaseConfig = {
  apiKey: import.meta.env.VITE_API_KEY,
  authDomain: import.meta.env.VITE_API_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_API_PROJECT_ID,
  storageBucket: import.meta.env.VITE_API_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_API_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_API_APP_ID,
};

//Initialize Firebase
export const app = initializeApp(firebaseConfig);
getStorage(app);
getFirestore(app);
const analytics = getAnalytics(app);

export const logAnalyticsAndHotjarEvent = (
  eventName: string,
  details?: Record<string, unknown>
) => {
  if (import.meta.env.VITE_ENVIRONMENT !== "dev") {
    //Google Analytics
    logEvent(analytics, eventName, details);
    //Hotjar
    if (hotjar.initialized()) hotjar.event(eventName);
  }
};

// Liveness Config
Amplify.configure(awsexports);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "*",
        element: <NotFoundPage />,
      },
      {
        path: "/",
        element: <Navigate to="/personal" replace />,
      },
      // {
      //   path: "/empresarial",
      //   element: <TemplateInterpreter />,
      // },
      {
        path: "/personal",
        element: <TemplateInterpreter />,
      },
      {
        path: "/dni",
        element: <UploadDniCard />,
      },
      {
        path: "/prueba-de-vida",
        element: <LivenessValidationCard />,
      },
      {
        path: "/preconfirmacion",
        element: <PreconfirmationPage />,
      },
      {
        path: "/apertura-exitosa",
        element: <SuccessPage />,
      },
    ],
  },
]);

root.render(
  <ThemeProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
          <NextUIProvider>
            <RouterProvider router={router} />
          </NextUIProvider>
        </Sentry.ErrorBoundary>
      </PersistGate>
    </Provider>
  </ThemeProvider>
);

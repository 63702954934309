import PadlockIconByma from "../../../assets/images/icons/ui/security-icon-padlock-green-byma.svg";

import { HeaderModalProps } from "./types";

import styles from "./TokenCard.module.scss";

const HeaderModal = ({ title, subtitle, className }: HeaderModalProps) => {
  const { headerModal, subtitleStyle } = styles;

  return (
    <header className={`${headerModal} ${className ?? ""}`}>
      <img src={PadlockIconByma} className="mr-4" />
      <div>
        <h2>{title}</h2>
        <h3 className={subtitleStyle}>{subtitle}</h3>
      </div>
    </header>
  );
};

export default HeaderModal;

import { MouseEventHandler } from "react";
import styles from "./Stepper.module.scss";
import useWindowWidth from "../../../hooks/useWindowWidth";

interface StepProps {
  title: string;
  subtitle: string;
  currentStep: "active" | "completed" | "disabled";
  handleClick?: MouseEventHandler<HTMLDivElement> | undefined;
  isFirstStep: boolean;
  isLastStep: boolean;
  nextStepStatus: "completed" | "disabled";
  previousStepStatus: "completed" | "disabled";
  currentStepWasCompleted: boolean;
}
const Step = ({
  title,
  subtitle,
  currentStep,
  isFirstStep,
  isLastStep,
  handleClick,
  nextStepStatus,
  previousStepStatus,
  currentStepWasCompleted,
}: StepProps) => {
  const {
    stepContainer,
    titleContainer,
    textCompleted,
    textActive,
    textDisabled,
    titleStyle,
    subtitleStyle,
    line1,
    line2,
    lineDisabled,
    lineCompleted,
    lineGreen,
  } = styles;

  const dimensions = useWindowWidth();

  const textStyles = {
    active: textActive,
    disabled: textDisabled,
    completed: textCompleted,
  };

  const lineStyle = {
    disabled: lineDisabled,
    completed: lineCompleted,
  };
  return (
    <div
      className={`${stepContainer} ${styles[currentStep]} `}
      onClick={handleClick}
    >
      <div className={`${textStyles[currentStep]}`}>
        <div className={titleContainer}>
          <div
            style={{ visibility: isFirstStep ? "hidden" : "visible" }}
            className={`${line1} ${lineStyle[previousStepStatus]} ${
              currentStepWasCompleted ? lineGreen : ""
            }`}
          ></div>
          <p className={titleStyle}>
            <b>{title}</b>
          </p>
          <div
            style={{ visibility: isLastStep ? "hidden" : "visible" }}
            className={`${line2} ${lineStyle[nextStepStatus]} ${
              nextStepStatus === "disabled" && currentStep ? lineGreen : ""
            }`}
          ></div>
        </div>
        {dimensions.width > 1173 && (
          <p className={subtitleStyle}>
            <span>{subtitle}</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default Step;

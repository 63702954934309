"use client";
import { useState } from "react";
import styles from "./Alert.module.scss";

import InfoIconByma from "../../../assets/images/icons/ui/ui-icon-info-byma.svg";
import ErrorIconByma from "../../../assets/images/icons/ui/ui-icon-error-byma.svg";
import WarnIconByma from "../../../assets/images/icons/ui/ui-icon-warn-byma.svg";
import ChevronDownByma from "../../../assets/images/icons/ui/ui-icon-chevron-down-byma.svg";
import ChevronUpByma from "../../../assets/images/icons/ui/ui-icon-chevron-up-byma.svg";

import { AlertProps } from "./types";

/**
 * Display brief messages for the user without interruptions
 */
const Alert = ({ children, variant, className, hasSummary }: AlertProps) => {
  const {
    container,
    iconStyle,
    infoBackground,
    warnBackground,
    errorBackground,
    clickable,
    summary,
  } = styles;

  const alertVariants = {
    INFO: {
      icon: <img src={InfoIconByma} className={iconStyle} />,
      background: infoBackground,
    },
    WARN: {
      icon: <img src={WarnIconByma} className={iconStyle} />,
      background: warnBackground,
    },
    ERROR: {
      icon: <img src={ErrorIconByma} className={iconStyle} />,
      background: errorBackground,
    },
  };

  const { icon, background } = alertVariants[variant];

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  return (
    <div
      className={`${container} ${background} ${hasSummary && clickable} ${
        className ?? ""
      }`}
      onClick={() => setIsDetailsOpen(!isDetailsOpen)}
    >
      {icon}
      {hasSummary ? (
        <div className="flex w-full justify-between items-center gap-4">
          <p className={`${!isDetailsOpen && summary}`}>{children}</p>
          <div>
            {isDetailsOpen ? (
              <img src={ChevronUpByma} alt="" />
            ) : (
              <img src={ChevronDownByma} alt="" />
            )}
          </div>
        </div>
      ) : (
        <p>{children}</p>
      )}
    </div>
  );
};

export default Alert;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import type { PreconfirmationStep } from "../../../types";
import { Option } from "../../../types";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { onUpdatePreconfirmation } from "../../../reducers/preconfirmationReducer";
import {
  onUpdateStep,
  onUpdateSubStep,
} from "../../../reducers/onboardingDataReducer";
import { Button } from "../../../components/ui/Button";
import useWindowWidth from "../../../hooks/useWindowWidth";

import EditIconByma from "../../../assets/images/icons/ui/edit-byma.svg?react";
import Arrow from "../../../assets/images/icons/ui/arrow.svg?react";

import styles from "./PreconfirmationStep.module.scss";

const PreconfirmationStep = ({
  name,
  columns,
  fields,
  editStep,
}: PreconfirmationStep) => {
  const {
    stepContainer,
    header,
    button,
    body,
    field,
    textArea,
    twoColumns,
    fourColumns,
    open,
  } = styles;

  const [openStep, setOpenStep] = useState<boolean>(false);

  const { formValues } = useAppSelector(({ onboardingData }) => onboardingData);

  const { onboardingType } = useAppSelector(
    ({ activeTemplate }) => activeTemplate
  );

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleEdit = ({
    step,
    substep,
  }: {
    step: number;
    substep?: number;
  }) => {
    dispatch(onUpdatePreconfirmation(true));
    dispatch(onUpdateStep(Number(step)));
    substep && dispatch(onUpdateSubStep(substep));
    navigate(onboardingType === "PHYSICAL" ? "/personal" : "/empresarial");
  };

  const conditionalFieldHasValue = (name: string) => {
    if (!formValues) return false;

    const splittedName = name.split(".");
    const nestedValue = splittedName.reduce(
      (acc, key) => acc?.[key],
      formValues
    );

    return !!nestedValue;
  };

  const getValue = (
    name: string,
    formatInPreConfirmation?: (value: string) => string,
    options?: Option[]
  ) => {
    const splittedName = name.split(".");

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let nestedValue: any = splittedName.reduce(
      (acc, key) => acc?.[key],
      formValues
    );

    // Get the label of the selected options
    if (options) {
      const hasMultipleValues = Array.isArray(nestedValue);
      const selectedOptions = hasMultipleValues
        ? nestedValue?.flatMap((val: string) =>
            options?.filter((option) => option.value === val)
          )
        : options?.find((option) => option.value === nestedValue);

      nestedValue = hasMultipleValues
        ? selectedOptions?.map((option: Option) => option.label).join(", ")
        : selectedOptions?.label;
    }

    if (formatInPreConfirmation)
      nestedValue = formatInPreConfirmation(nestedValue);

    return nestedValue || "-";
  };

  const dimensions = useWindowWidth();
  const isMobile = dimensions.width < 768;

  return (
    <section className={stepContainer}>
      <div className={header}>
        <div onClick={() => setOpenStep((prev) => !prev)}>
          <h3>{name}</h3>
          {isMobile && <Arrow className={`${openStep ? open : ""}`} />}
        </div>
        <Button
          text="Editar"
          variant="tertiary"
          onClick={() => handleEdit(editStep)}
          className={button}
          endContent={<EditIconByma />}
        />
      </div>
      {(!isMobile || openStep) && (
        <div className={`${body} ${columns === 2 ? twoColumns : fourColumns}`}>
          {fields?.map(
            ({
              name,
              label,
              type,
              options,
              isConditional,
              formatInPreConfirmation,
            }) => {
              if (!isConditional || conditionalFieldHasValue(name))
                return (
                  <div
                    key={name}
                    className={`${field} ${
                      columns === 4 && type === "textarea" ? textArea : ""
                    }`}
                  >
                    <span>{label}</span>
                    <span>
                      {getValue(name, formatInPreConfirmation, options)}
                    </span>
                  </div>
                );
            }
          )}
        </div>
      )}
    </section>
  );
};

export default PreconfirmationStep;
